import React from 'react'
import './about.css';

const About = () => {
  return (
    <section id='about'>
        <span className='aboutTitle'>About me</span>
        <span className='aboutDescription'>I'm currently a student at Marist College pursuing a bachelor's degree in both Computer Science and Cyber Security, as well as a minor in
        Information Technology. I love web development and aspire to learn more <span className='bold'>everyday</span></span>
    </section>
  )
}

export default About
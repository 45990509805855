import React, {useRef} from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import './contact.css'
import emailjs from '@emailjs/browser';

library.add(faGithub, faLinkedinIn)

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
      e.preventDefault();

      emailjs.sendForm('service_ess19ab', 'template_entplbu', form.current, 'BDDdCdeHggsfYLkPP')
        .then((result) => {
            console.log(result.text);
            alert('Email Sent!')
            e.target.reset()
        }, (error) => {
            console.log(error.text);
        });
  };
  return (
    <section id='contactPage'>
        <div className='contact'>
            <h1 className='contactPageTitle'>Contact Me</h1>
            <span className='contactDescription'>Please fill out below to get in contact with me</span>
            <form className='contactForm' ref={form} onSubmit={sendEmail}>
                <input type='text' className='name' placeholder='Your Name' name='your_name'/>
                <input type='email' className='email' placeholder='Your Email'name='your_email'/>
                <textarea className='msg' name='message'rows="5" placeholder='Your Message'/>
                <button type='submit' value='send' className='submitBtn'>Submit</button>
                <div className='links'>
                    <a target="_blank" rel="noreferrer" href='https://github.com/EthanOndreicka'><FontAwesomeIcon icon={faGithub} className='link'/></a>
                    <a target="_blank" rel="noreferrer" href='https://www.linkedin.com/in/ethan-ondreicka-7b6624212/'><FontAwesomeIcon icon={faLinkedinIn} className='link'/></a>
                </div>

            </form>
        </div>
    </section>
  )
}

export default Contact
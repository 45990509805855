import React from 'react'
import './works.css'
import Portfolio1 from '../../assets/portfolio-1.png'
import Portfolio2 from '../../assets/portfolio-2.png'
import Portfolio3 from '../../assets/portfolio-3.png'

const Works = () => {
  return (
    <section id='works'>
        <h2 className='worksTitle'>My Projects</h2>
        <span className='worksDescription'>I spend lots of time creating projects that I personally use on a daily basis. These projects have 
        helped me develop skills in many areas and has provided great practice.</span>
        <div className='worksImgs'>
            <img src={Portfolio1} alt='' className='worksImg'/>
            <img src={Portfolio2} alt='' className='worksImg'/>
            <img src={Portfolio3} alt='' className='worksImg'/>
        </div>
    </section>
  )
}

export default Works